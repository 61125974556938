p,
a,
li,
blockquote,
form,span {
    -webkit-font-smoothing: antialiased;
	font-weight: 500;
}

p {
	margin-top: 0;
	margin-bottom: 20px;
}

a {
  text-decoration: none;
  color: inherit;
}

.matrixElement a {
	color: #ef444e;
	text-decoration: underline;
}

a.white {
	color: #fff;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 700;
}

// as determined by design
h1,
.h1 {
	@include font-size(49px);
	line-height: rem-calc(64px);
	font-family: $header-font-family;
}

@media screen and (max-width: $medium) {
    h1,
    .h1 {
    	@include font-size(33px);
    	line-height: rem-calc(40px);
    }
}

h2,
.h2 {
	@include font-size(30px);
	line-height: rem-calc(40px);
	font-family: $header-font-family;
}

@media screen and (max-width: $medium) {
	h2,
	.h2 {
		@include font-size(23px);
		line-height: rem-calc(30px);
	}
}

h3,
.h3 {
	@include font-size(26px);
	line-height: rem-calc(34px);
	font-family: $header-font-family;
}

@media screen and (max-width: $medium) {
	h3,
	.h3 {
		@include font-size(20px);
		line-height: rem-calc(26px);
	}
}

h4,
.h4 {
	@include font-size(22px);
	line-height: rem-calc(29px);
	font-family: $header-font-family;
}

@media screen and (max-width: $medium) {
	h4,
	.h4 {
		@include font-size(18px);
		line-height: rem-calc(23px);
	}
}

h5,
.h5 {
	@include font-size(19px);
	line-height: rem-calc(25px);
	font-family: $header-font-family;
}

@media screen and (max-width: $medium) {
	h5,
	.h5 {
		@include font-size(16px);
		line-height: rem-calc(21px);
	}
}

h6,
.h6 {
	@include font-size(16px);
	line-height: rem-calc(21px);
	font-family: $header-font-family;
}

@media screen and (max-width: $medium) {
	h6,
	.h6 {
		@include font-size(14px);
		line-height: rem-calc(18px);
	}
}

::selection {
	background-color: #ef444e;
	color: #fff;
	background-color: var(--select-bg);
	color: var(--select-text);
}