.stage-timeline {
    position: relative;
    &:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        border-bottom: 2px dashed rgba(70, 70, 70, 0.2);
        height: 1px;
        top: 0;
        bottom: 0;
        margin: auto;
        z-index: 0;
    }
    .date {
        color: #7e7e7e;
        font-size: 14px;font-weight: 500;
        line-height: 28px;
        text-align: center;
    }
}

.filter {
    input[type="checkbox"] {
        width: inherit;
        appearance: checkbox;
        height: auto;
    }
    label {
        display: inline;
        width: 100%;
    }
    .checkbox {
        margin-right: 10px;
        float: left;
        label {
            width: auto;
        }
    }
}