.col {
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
}

.col-auto {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
}

.w-1\/12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.333333%;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
}

.w-2\/12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.666667%;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
}

.w-3\/12,
.w-1\/4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
}

.w-4\/12,
.w-1\/3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.333333%;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
}

.w-5\/12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.666667%;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
}

.w-6\/12,
.w-1\/2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}

.w-7\/12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.333333%;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
}

.w-8\/12,
.w-2\/3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.666667%;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
}

.w-9\/12,
.w-3\/4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
}

.w-10\/12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.333333%;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
}

.w-11\/12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.666667%;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
}

.w-12\/12,
.w-full {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}

@each $name, $size in $screens {

	@media screen and (max-width: #{$size}) {
		.#{$name}\:col {
            -webkit-flex-basis: 0;
            -ms-flex-preferred-size: 0;
            flex-basis: 0;
            -webkit-box-flex: 1;
            -webkit-flex-grow: 1;
            -ms-flex-positive: 1;
            flex-grow: 1;
            max-width: 100%;
		}

		.#{$name}\:col-auto {
            -webkit-box-flex: 0;
            -webkit-flex: 0 0 auto;
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;
            width: auto;
		}

		.#{$name}\:w-1\/12 {
            -webkit-box-flex: 0;
            -webkit-flex: 0 0 8.333333%;
            -ms-flex: 0 0 8.333333%;
            flex: 0 0 8.333333%;
            max-width: 8.333333%;
		}

		.#{$name}\:w-2\/12 {
            -webkit-box-flex: 0;
            -webkit-flex: 0 0 16.666667%;
            -ms-flex: 0 0 16.666667%;
            flex: 0 0 16.666667%;
            max-width: 16.666667%;
		}

		.#{$name}\:w-3\/12,
		.#{$name}\:w-1\/4 {
            -webkit-box-flex: 0;
            -webkit-flex: 0 0 25%;
            -ms-flex: 0 0 25%;
            flex: 0 0 25%;
            max-width: 25%;
		}

		.#{$name}\:w-4\/12,
		.#{$name}\:w-1\/3 {
            -webkit-box-flex: 0;
            -webkit-flex: 0 0 33.333333%;
            -ms-flex: 0 0 33.333333%;
            flex: 0 0 33.333333%;
            max-width: 33.333333%;
		}

		.#{$name}\:w-5\/12 {
            -webkit-box-flex: 0;
            -webkit-flex: 0 0 41.666667%;
            -ms-flex: 0 0 41.666667%;
            flex: 0 0 41.666667%;
            max-width: 41.666667%;
		}

		.#{$name}\:w-6\/12,
		.#{$name}\:w-1\/2 {
            -webkit-box-flex: 0;
            -webkit-flex: 0 0 50%;
            -ms-flex: 0 0 50%;
            flex: 0 0 50%;
            max-width: 50%;
		}

		.#{$name}\:w-7\/12 {
            -webkit-box-flex: 0;
            -webkit-flex: 0 0 58.333333%;
            -ms-flex: 0 0 58.333333%;
            flex: 0 0 58.333333%;
            max-width: 58.333333%;
		}

		.#{$name}\:w-8\/12,
		.#{$name}\:w-2\/3 {
            -webkit-box-flex: 0;
            -webkit-flex: 0 0 66.666667%;
            -ms-flex: 0 0 66.666667%;
            flex: 0 0 66.666667%;
            max-width: 66.666667%;
		}

		.#{$name}\:w-9\/12,
		.#{$name}\:w-3\/4 {
            -webkit-box-flex: 0;
            -webkit-flex: 0 0 75%;
            -ms-flex: 0 0 75%;
            flex: 0 0 75%;
            max-width: 75%;
		}

		.#{$name}\:w-10\/12 {
            -webkit-box-flex: 0;
            -webkit-flex: 0 0 83.333333%;
            -ms-flex: 0 0 83.333333%;
            flex: 0 0 83.333333%;
            max-width: 83.333333%;
		}

		.#{$name}\:w-11\/12 {
            -webkit-box-flex: 0;
            -webkit-flex: 0 0 91.666667%;
            -ms-flex: 0 0 91.666667%;
            flex: 0 0 91.666667%;
            max-width: 91.666667%;
		}

		.#{$name}\:w-12\/12,
		.#{$name}\:w-full {
            -webkit-box-flex: 0;
            -webkit-flex: 0 0 100%;
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
		}
	}
}

@media only screen and (min-width: 768px) {
    .grid {
        display: grid;
        grid-gap: 40px;
        grid-template-columns: repeat(auto-fill, minmax(300px,1fr));
        grid-auto-rows: 24px;
    }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .grid {
        display: block;
    }
    .grid .item {
        width: 40%;
        margin-bottom: 30px;
        float: left;
        margin-left: 30px;
        margin-right: 30px;
    }
}

@supports (-ms-accelerator:true) {
    .grid {
        display: block;
    }
    .grid .item {
        width: 40%;
        margin-bottom: 30px;
        float: left;
        margin-left: 30px;
        margin-right: 30px;
    }
}
