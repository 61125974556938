.themas {
    &__item {
        display: block;
        background-color: white;
        background-size: cover;
        background-position: center center;
        position: relative;
        text-align: left;
        height: 200px;
        overflow: hidden;
        .title {
            position: absolute;
            bottom: 20px;
            left: 20px;
            z-index: 2;
        }
        span.aLink {
            float: right;
            position: absolute;
            right: 0;
            bottom: 0;
            z-index: 9;
            padding: 10px 15px 10px 5px;
            transition: all ease 0.2s;
            &:before {
                content: '';
                z-index: -1;
                border-radius: 75px;
                background-color: $colour-secondary;
                position: absolute;
                right: -65px;
                bottom: -65px;
                height: 125px;
                width: 125px;

            }
        }
        &:hover {
            span.aLink {
                padding: 10px;
            }

        }
    }
    &__overlay {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100px;
        background: linear-gradient(180deg, rgba(24, 24, 24, 0) 0%, rgba(24, 24, 24, 0.8) 100%);
        z-index: 1;
    }
}

.matrixElement {
    ul,
    ol {
        list-style: none;
        margin-left: 0;
        padding-left: 0;
    }

    ul li {
        position: relative;
        padding-left: 1em;

        &:before {
            font-size: 18px;
            content: '•';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    ol {
        counter-reset: list;

        > li {
            position: relative;
            padding-left: 2em;

            &:before {
                counter-increment: list;
                content: '0' counter(list);
                color: inherit;
                display: inline-block;
                position: absolute;
                left: 0;
            }

            // This selected every adjacent <li>
            // from the 10th and overrides the
            // content property of each
            &:nth-child(10n) ~ li:before,
            &:nth-child(10n):before {
                content: counter(list);
            }
        }
    }

    ul ol,
    ol ul {
        padding-top: 0;
    }
}