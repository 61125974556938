input[type="text"],
input[type="email"],
input[type="password"],
textarea,
select {
    border: 1px solid $black;
    height: inherit;
    background-color: transparent;
    margin-bottom: 20px;
    font-family: 'Quicksand';
}

select, input {
    height: 50px;
}

textarea {
    height: 300px;
    line-height: 25px;
}

label {
    padding-bottom: 10px;
    display: block;
    font-weight: 700;
}

textarea {
    resize: none;
    width: 100%;
    padding-left: 10px;
    border: 0;
    border-bottom: 2px solid $grey;
    &:focus {
        outline: 0;
        border-bottom: 2px solid $orange;
    }
}

input[type="text"],
input[type="email"],
input[type="number"],
input[type="password"]
{
    border: 0;
    border-bottom: 2px solid $grey;
    &:focus {
        outline: 0;
        border-bottom: 2px solid $orange;
    }
}

.gform_button {
    &.button {
        width: auto;
        padding: 0px 40px;
        font-size: 15px;
        font-weight: bold;
        color: $white;
        background: $colour-font;
        text-transform: uppercase;
        font-family: 'Quicksand';
        transition: all 0.3s ease;
        &:hover {
            background-color: $colour-primary;
            border-color: $colour-primary;
        }
    }
}
input[type="checkbox"] {
    display: inline;
    vertical-align: middle;
    -webkit-appearance: checkbox;
    -moz-appearance: checkbox;
    appearance: checkbox;
}

input[type="radio"] {
    display: inline;
    vertical-align: middle;
    -webkit-appearance: radio;
    -moz-appearance: radio;
    appearance: radio;
}

button[type="submit"] {
    background-color: #ff9b38;
    color: #fff;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.ginput_recaptcha {
    display: inline-block !important;
    float: left;
}

.gfield {
    position: relative;
}

.validation_error {
    margin-bottom: 20px;
}

.gfield_error {
    input {
        border: 1px solid red;
    }
}

select {
    &.disabled {
        opacity: 0.3;
    }
}
