.button {
    display: inline-block;
    vertical-align: top;
    text-align: center;
    border-radius: 0;
    font-weight: 700;
    padding: 16px 30px;
    color: $white;
    text-decoration: none;
    border: 0;
    outline: none;
    font-size: 14px;
    &--white {
        color: $grey;
        background-color: $white;
    }
    &--red {
        background-color: $colour-primary;
        color: $white;
    }
}
