// Navigation
nav {
    ul {
        padding: 0;
        margin: 0;
    }
    li {
        padding: 0;
        margin: 0;
        display: block;
        &:before {
            content: '';
        }
    }
}

.mobile-menu--logo {
    opacity: 0;
    position: absolute;
    top: 10px;
    left: 25px;
    z-index: -1;
    transition: all ease 0.7s;
    &.active {
        opacity: 1;
        z-index: 99999;
    }
}


.nav {
    background-color: $colour-primary;
    height: 0px;
    z-index: -1;
    padding: 0px;
    overflow: hidden;
    &.open {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100vh;
        text-align: center;
    }
    @media only screen and (min-width: $medium) {
        overflow: inherit;
        margin-top: 50px;
        height: inherit;
        position: inherit;
        text-align: inherit;
        padding-top: inherit;
        background-color: inherit;
    }
    &__item {
        width: 100%;
        @media only screen and (min-width: $medium) {
            display: inline;
            padding: 10px 9px;
            position: relative;
        }
        @media only screen and (min-width: $large) {
            padding: 10px 15px;
        }
        a {
            color: #fff;
            font-weight: 700;
        }
        &.level1 {
            padding: 2vh 0;
            @media only screen and (min-width: $medium) {
                padding: 10px 9px;
            }
            @media only screen and (min-width: $large) {
                padding: 10px 15px;
            }
        }
        &.has-children img {
            transition: all ease 0.2s;
        }
        &.has-children ul {
            @media only screen and (min-width: $medium) {
                &:before {
                    content: ' ';
                    height: 14px;
                    width: 14px;
                    transform: rotate(45deg);
                    position: absolute;
                    top: -7px;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    background-color: $colour-primary;
                }
                position: absolute;
                top: 100%;
                right: calc(50% + 7px);
                transform: translateX(50%);
                width: 300px;
                background-color: $colour-primary;
                li {
                    display: block;
                    text-align: left;
                    position: relative;
                    width: 98%;
                    transition: all ease 0.2s;
                    a {
                        display: inline-block;
                        width: 100%;
                    }
                    &:hover {
                        width: 100%;
                    }
                    &:after {
                        content: ' ';
                        position: absolute;
                        right: 0;
                        height: 20px;
                        width: 30px;
                        background-image: url(/img/site/pijlt-wit-rechts.svg);
                        background-repeat: no-repeat;
                        background-position: center center;
                        background-position-y: 10px;
                        background-position-x: 0px;
                    }
                }
            }
        }
        &.has-children:hover img {
            transform: rotate(-90deg);
        }
        &.has-children:hover ul {
            display: block;
        }
    }
}

.topNav {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
    height: 219px;
    &__logo {
        padding: 10px;
        @media only screen and (min-width: $medium) {
            padding: 30px 0;
        }
        img {
            max-width: 100px;
            @media only screen and (min-width: $medium) {
                max-width: 170px;
            }
        }
    }
    &__menu {
        @media only screen and (min-width: $medium) {
            position: relative;
        }
        text-align: right;
        vertical-align: middle;
    }
}
