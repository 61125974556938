.vragen {
    background-color: $colour-secondary;
    color: #fff;
    padding: 20px;
    @media only screen and (min-width: $medium) {
        padding: 40px;
    }
    p {
        margin: 0;
    }
    a {
        color: #fff;
    }
    &__image {
        img {
            border-radius: 50%;
            max-width: 100%;
        }
    }
    &__text {
        padding-top: 20px;
        clear: both;
        a {
            text-decoration: none;
        }
    }
}

.faq {
    &__item {
        height: auto;
        border-bottom: 1px solid #ddeaf6;
        background-color: #eef4fa;
        cursor: pointer;
        &.active {
            .faq__question {
                &:after {
                    transform: rotate(90deg);
                }
                &:before {
                    transform: rotate(180deg);
                }
            }
            .faq__answer {
                max-height: 400px;
                transition: all ease 1s;
            }
        }
    }
    &__question {
        padding: 16px 24px;
        position: relative;
        max-width: 98%;
        h5 {
            margin: 0;
            padding: 0;
        }
        &:after {
            position: absolute;
            content: '';
            right: 24px;
            top: 16px;
            background-color: $colour-secondary;
            height: 16px;
            width: 2px;
            margin-top: 5px;
            transition: all ease-in-out 0.5s;
        }
        &:before {
            position: absolute;
            content: '';
            background-color: $colour-secondary;
            right: 17px;
            top: 23px;
            height: 2px;
            width: 16px;
            margin-top: 5px;
            transition: all ease-in-out 0.6s;
        }
    }
    &__answer {
        padding: 0 24px;
        overflow: hidden;
        max-height: 0;
        transition: all ease 1s;
    }
}
