
.hero {
    position: relative;
    overflow: hidden;
    height: 400px;
    @media only screen and (min-width: $medium) {
        &--home {
            height: inherit;
        }
        height: inherit;
    }
    &__background {
        background-image: url('/img/site/cover.jpg');
        background-size: cover;
        background-position: center;
        height: inherit;
        @media only screen and (min-width: $medium) {
            background-image: inherit;
            height: inherit;
        }
        img {
            display: none;
            @media only screen and (min-width: $medium) {
                display: inline;
            }
            max-width: 100%;
        }
    }
    &__gradient {
        position: absolute;
        top: -1px;
        right: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        display: block !important;
        height: 150px;
        background: linear-gradient(180deg, rgba(24, 24, 24, 0.4) 0%, rgba(24, 24, 24, 0) 100%);
    }
    &__overlay {
        position: absolute;
        bottom: -1px;
        right: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        display: block !important;
    }
    &__rainbow {
        display: none;
        @media only screen and (min-width: $medium) {
            display: inline;
        }
        position: absolute;
        top: 30%;
        right: -20px;
        z-index: 0;
    }
}

.heroCard {
    margin-top: 2rem;
    &__item {
        &--large {
            margin-top: -12rem;
            padding: 15px;
            margin-right: 15px;
            margin-left: 15px;
            z-index: 1;
            position: relative;
            @media only screen and (min-width: $medium) {
                padding: 40px;
                margin-right: inherit;
                margin-left: inherit;
                margin-top: -5rem;
            }
            @media only screen and (min-width: $large) {
                padding: 80px;
                margin-top: -12rem;
                margin-right: inherit;
                margin-left: inherit;
            }
            background-color: $colour-primary;
            color: #fff;
        }
    }
    h1 {
        color: #fff;
    }
}

.planning {
    background-color: #f8fbff;
    margin-right: 15px;
    margin-left: 15px;
    @media only screen and (min-width: $medium) {
        margin-right: inherit;
        margin-left: inherit;
    }
    h4 {
        padding: 20px 20px 0;
        @media only screen and (min-width: $medium) {
            padding: 20px 40px;
            margin: 0;
        }
    }
    &__item {
        padding: 10px 20px;
        @media only screen and (min-width: $medium) {
            padding: 10px 40px;
        }
        width: 100%;
        height: auto;
        overflow: hidden;
        &:nth-child(even) {
            background-color: rgba(0, 0, 0, 0.02);
        }
        p {
            margin: 0;
        }
    }
}
