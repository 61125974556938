.relative {
    @media only screen and (min-width: $medium) {
        height: 666px;
    }
}
.sidebar {
    position: relative;
    left: 0;
    width: 100%;
    top: 0;
    bottom: 0;
    @media only screen and (min-width: $medium) {
        position: absolute;
        width: 33.3333%;
        height: 100%;
        top: 0;
        left: 0;
    }
    @media only screen and (min-width: $large) {
        overflow: hidden;
    }
    &:before {
        content: ' ';
        width: 1px;
        top: 23px;
        left: 13px;
        bottom: 0;
        border: 1px dashed rgba(29,29,29,0.1);
        position: absolute;
        z-index: 1;
    }
    &--stages:before {
        content: none;
    }
}
.map {
    position: relative;
    left: 0;
    width: 100%;
    top: 0;
    bottom: 0;
    height: 666px;
    right: 0;
    @media only screen and (min-width: $medium) {
        position: absolute;
        left: 35.3333%;
        width: 64.6666%;
        top: 0;
        bottom: 0;
        height: 666px;
    }
}

.listings {
    overflow: auto;
    padding-bottom: 60px;
    .item {
        display: block;
        padding: 10px 20px;
        text-decoration: none;
        &:nth-child(odd) {
            background-color: #f8fbff;
        }
        .date {
            color: #7e7e7e;
            font-size: 14px;
            font-weight: 500;
            line-height: 28px;
        }
        &:hover,
        &:hover .date,
        &.active,
        &.active .date {
            color: $white;
        }
    }
    &--construction {
        position: relative;
        height: 100%;
        transition: all ease 0.2s;
        .item {
            display: block;
            cursor: pointer;
            padding: 15px 10px;
            text-decoration: none;
            border-radius: 5px;
            .date-icon {
                display: none;
            }
            .date {
                color: #7e7e7e;
                font-size: 14px;
                font-weight: 500;
                line-height: 28px;
                display: none;
            }
            span {
                margin-top: 8px;
                margin-bottom: 5px;
                margin-right: 10px;
                z-index: 999;
                position: relative;
            }
            &:hover,
            &.active {
                background-color: #181818;
                padding: 5px 10px 15px;
                .date-icon, .date {
                    display: inline;
                    color: $white;
                }
                span {
                    margin-top: 27px;
                    margin-bottom: 20px;
                    margin-right: 10px;
                }
                color: $white;
                .icon-camera {
                    margin-top: 15px;
                }
            }
            &:hover,
            &:hover .date {
                color: $white;
            }
        }
    }
}


.modal-open {
    overflow: hidden
}

.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    outline: 0
}

.modal.fade .modal-dialog {
    -webkit-transform: translate(0, -25%);
    -ms-transform: translate(0, -25%);
    -o-transform: translate(0, -25%);
    transform: translate(0, -25%);
    -webkit-transition: -webkit-transform .3s ease-out;
    -o-transition: -o-transform .3s ease-out;
    transition: -webkit-transform .3s ease-out;
    transition: transform .3s ease-out;
    transition: transform .3s ease-out, -webkit-transform .3s ease-out, -o-transform .3s ease-out
}

.modal.in .modal-dialog {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0)
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: 10px
}

.modal-content {
    position: relative;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 0px;
    -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
    box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
    outline: 0
}

.modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: #000
}

.modal-backdrop.fade {
    filter: alpha(opacity=0);
    opacity: 0
}

.modal-backdrop.in {
    filter: alpha(opacity=50);
    opacity: .5
}

.modal-header {
    padding: 15px;
}

.modal-header .close {
    margin-top: -2px;
    color: #fff;
    font-size: 20px;
}

.modal-title {
    margin: 0;
    line-height: 1.42857143
}

.modal-body {
    position: relative;
    padding: 15px;
    padding-top: 0;
}

.modal-footer {
    padding: 15px;
    text-align: right;
}

.modal-footer .btn+.btn {
    margin-bottom: 0;
    margin-left: 5px
}

.modal-footer .btn-group .btn+.btn {
    margin-left: -1px
}

.modal-footer .btn-block+.btn-block {
    margin-left: 0
}

.modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll
}

@media (min-width:768px) {
    .modal-dialog {
        max-width: 886px;
        width: 100%;
        margin: 30px auto
    }
    .modal-content {
        -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
        box-shadow: 0 5px 15px rgba(0, 0, 0, .5)
    }
    .modal-sm {
        width: 300px
    }
}


@media (min-width:992px) {
    .modal-lg {
        width: 900px
    }
}

.matrixElement .modal-dialog .modal-body ul li:before {
    content: '' !important;
}

.modal {
    .slick-arrow {
        &.slick-disabled {
            opacity: 0;
        }
    }
}
