.col-lg-6 {
    width: 50%;
    float: left;
    input {
        width: 98%;
    }
    input[type="checkbox"] {
        width: inherit;
        appearance: checkbox;
        height: auto;
    }
    label {
        display: inline;
        width: 100%;
        float: left;
    }
    .checkbox {
        width: 50%;
        float: left;
        label {
            width: auto;
        }
    }
}

.col-lg-4 {
    width: 33.3333%;
    float: left;
    input {
        width: 98%;
    }
}

.row {
    clear: both;
}
