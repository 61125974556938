.team {
    background-color: #f8fbff;
    padding: 40px;
    @media only screen and (min-width: $medium) {
        padding: 30px;
    }
    h4 {
        margin-top: 0;
    }
    &__slider--container {
        padding: 10px 40px;
        background-color: #fff;
        box-shadow: 3px 3px 8px 0 rgba(27, 63, 111, 0.1);
    }
    &__slider {
        .item img {
            max-width: 100%;
        }
        .item {
            display: table;
            max-height: 150px;
            a {
                max-height: 150px;
                display: table-cell;
                vertical-align: middle;
                img {
                    max-height: 150px;
                }
            }
        }
    }
}
