.h-100 {
	height: 100%;
}

.order-1 {
	order: 1;
}

.order-2 {
	order: 2;
}

.flex-row {
	flex-direction: row;
}

.flex-wrap {
	flex-wrap: wrap;
}

.flex-nowrap {
	flex-wrap: nowrap;
}

.flex-column {
	flex-direction: column;
}

.align-start {
	align-items: flex-start;
}

.align-center {
	align-items: center;
}

.align-end {
	align-items: flex-end;
}

.align-baseline {
	align-items: baseline;
}

.align-stretch {
	align-items: stretch;
}

.justify-start {
	justify-content: flex-start;
}

.justify-center {
	justify-content: center;
}

.justify-end {
	justify-content: flex-end;
}

.justify-between {
	justify-content: space-between;
}

.justify-around {
	justify-content: space-around;
}

.align-self-start {
	align-self: flex-start;
}

.align-self-center {
	align-self: center;
}

.align-self-end {
	align-self: flex-end;
}

.align-self-baseline {
     align-self: baseline;
}

.align-self-stretch {
     align-self: stretch;
}

@each $prefix, $size in $screens {
	@media screen and (max-width: #{$size}) {
		.#{$prefix}\:h-100 {
			height: 100%;
		}

		.#{$prefix}\:order-1 {
			order: 1;
		}

		.#{$prefix}\:order-2 {
			order: 2;
		}

		.#{$prefix}\:flex-row {
			flex-direction: row;
		}

		.#{$prefix}\:flex-wrap {
			flex-wrap: wrap;
		}

		.#{$prefix}\:flex-nowrap {
			flex-wrap: nowrap;
		}

		.#{$prefix}\:flex-column {
			flex-direction: column;
		}

		.#{$prefix}\:align-start {
			align-items: flex-start;
		}

		.#{$prefix}\:align-center {
			align-items: center;
		}

		.#{$prefix}\:align-end {
			align-items: flex-end;
		}

		.#{$prefix}\:align-baseline {
			align-items: baseline;
		}

		.#{$prefix}\:align-stretch {
			align-items: stretch;
		}

		.#{$prefix}\:justify-start {
			justify-content: flex-start;
		}

		.#{$prefix}\:justify-center {
			justify-content: center;
		}

		.#{$prefix}\:justify-end {
			justify-content: flex-end;
		}

		.#{$prefix}\:justify-between {
			justify-content: space-between;
		}

		.#{$prefix}\:justify-around {
			justify-content: space-around;
		}

		.#{$prefix}\:align-self-start {
			align-self: flex-start;
		}

		.#{$prefix}\:align-self-center {
			align-self: center;
		}

		.#{$prefix}\:align-self-end {
			align-self: flex-end;
		}

		.#{$prefix}\:align-self-baseline {
			align-self: baseline;
		}

		.#{$prefix}\:align-self-stretch {
			align-self: stretch;
		}
	}
}
