// http://chir.ag/projects/name-that-color/

// Core colours
$white: #ffffff;
$black: #000000;
$charcoal: #414042;
$grey: #464646;
$grey-light: #f8fbff;

// Greys
$gallery: #eeeeee;

// Theme Colours
$forest-green: #9cc68b;
$pink: #5b108b;
$purple: #ae2a71;
$palm-green: #0d270a;
$yellow: #ffcd78;
$red: #ef444e;
$orange: #ff9b38;
$verdino-blue: #014289;
$green: #38c172;
$dark-green: #9cc68b;


// Variables
$colour-primary: $red;
$colour-accent: $pink;
$colour-secondary: $orange;
$colour-fourth: $yellow;

//Variables darkened
$colour-primary-darkened: darken($colour-primary, 10%);
$colour-accent-darkened: darken($colour-accent, 10%);
$colour-secondary-darkened: darken($colour-secondary, 10%);


$colour-background: $white;
$colour-inner-background: $gallery;

$colour-font: $grey;

$theme-colours: (
	white: $white,
	black: $black,
	grey: $grey,
	charcoal: $charcoal,
	colour-primary: $colour-primary,
	colour-accent: $colour-accent,
	colour-secondary: $colour-secondary,
	colour-primary-darkened: $colour-primary-darkened,
	colour-accent-darkened: $colour-accent-darkened,
	colour-secondary-darkened: $colour-secondary-darkened,
	colour-background: $colour-background,
	colour-inner-background: $colour-inner-background,
	colour-font: $colour-font,
	forest-green: $forest-green,
	dark-green: $forest-green,
	green: $green,
	pink: $pink,
	orange: $orange,
	yellow: $yellow,
	red: $red,
	gallery: $gallery,
	palm-green: $palm-green,
	verdino-blue: $verdino-blue,
	grey-light: $grey-light,
);

//social site colours
$twitter-blue: #55ACEE;
$facebook-blue: #39619F;
$google-red: #F33F2C;
